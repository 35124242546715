<div class="ds-funfacts-area pt-100 pb-70" style="background-image: url(assets/img/data-science/funfacts/funfacts-bg.jpg);">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-3 col-sm-6 wow animate__animated animate__fadeInLeft" data-wow-delay="00ms" data-wow-duration="1000ms">
                <div class="ds-funfacts-card">
                    <div class="content">
                        <div class="icon">
                            <img src="assets/img/icons/club.svg" alt="image">
                        </div>
                        <h3>
                            <span>1000</span>
                            <span class="plus">+</span>
                        </h3>
                        <p>Local Associations</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 wow animate__animated animate__fadeInLeft" data-wow-delay="00ms" data-wow-duration="1000ms">
                <div class="ds-funfacts-card">
                    <div class="content">
                        <div class="icon">
                            <img src="assets/img/icons/event.svg" alt="image">
                        </div>
                        <h3>
                            <span>12,000</span>
                            <span class="plus">+</span>
                        </h3>
                        <p>Completed Events</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 wow animate__animated animate__fadeInRight" data-wow-delay="00ms" data-wow-duration="1000ms">
                <div class="ds-funfacts-card">
                    <div class="content">
                        <div class="icon">
                            <img src="assets/img/icons/team.svg" alt="image">
                        </div>
                        <h3>
                            <span>65,000</span>
                            <span class="plus">+</span>
                        </h3>
                        <p>Registered Teams</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 wow animate__animated animate__fadeInRight" data-wow-delay="00ms" data-wow-duration="1000ms">
                <div class="ds-funfacts-card">
                    <div class="content">
                        <div class="icon">
                            <img src="assets/img/icons/game.svg" alt="image">
                        </div>
                        <h3>
                            <span>270,000</span>
                            <span class="plus">+</span>
                        </h3>
                        <p>Scheduled Games</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>